<template>
  <div>
    <h1 class="text-h4">{{ $lang.header[$route.name] }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-alert dark color="secondary" :data-cy="$lang.hints.gdprHint">{{ $lang.hints.gdprHint }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-expansion-panels color="surface">
          <template v-for="(type, i) in gdprTypes">
            <v-expansion-panel
              :key="i"
            >
              <v-expansion-panel-header>
                {{ $lang.routes[type] }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <Table
                  :key="tableKey"
                  :items="gdpr[type]"
                  :type="type"
                  :loading="loading"
                  :all-types="gdprTypes"
                  @fetchGdpr="fetchGdpr($event)"
                ></Table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './GdprDatatable'
import {
  cronTriggersGdprReportUsingGET as getGdprCronTrigger,
  modulesGdprReportUsingGET as getGdprModules,
  processCredentialsGdprReportUsingGET as getGdprProcessCredentials,
  processesGdprReportUsingGET as getGdprProcesses,
  restTriggersGdprReportUsingGET as getGdprRestTrigger,
  messagingTriggersGdprReportUsingGET as getGdprMessagingTrigger,
  librariesGdprReportUsingGET as getGdprLibraries
} from '@/utils/api'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      gdpr: {
        gdprCronTrigger: [],
        gdprRestTrigger: [],
        gdprModules: [],
        gdprProcessCredentials: [],
        gdprProcesses: [],
        gdprMessagingTrigger: [],
        gdprLibraries: []
      },
      gdprTypes: [
        'gdprCronTrigger',
        'gdprRestTrigger',
        'gdprModules',
        'gdprProcessCredentials',
        'gdprProcesses',
        'gdprMessagingTrigger',
        'gdprLibraries'
      ]
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
    this.gdprTypes.forEach((type) => this.fetchGdpr(type))
  },
  methods: {
    async fetchGdpr(type) {
      this.loading = true

      let res = null

      try {

        switch (type) {
        case 'gdprCronTrigger':
          res = await getGdprCronTrigger()
          break
        case 'gdprRestTrigger':
          res = await getGdprRestTrigger()
          break
        case 'gdprModules':
          res = await getGdprModules()
          break
        case 'gdprProcessCredentials':
          res = await getGdprProcessCredentials()
          break
        case 'gdprProcesses':
          res = await getGdprProcesses()
          break
        case 'gdprMessagingTrigger':
          res = await getGdprMessagingTrigger()
          break
        case 'gdprLibraries':
          res = await getGdprLibraries()
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          this.loading = false

          return
        }

        this.gdpr[type] = res && res.data ? res.data.data : []
        this.loading = false

      } catch (err) {
        this.loading = false
      }
    }
  }
}
</script>
